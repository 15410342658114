<template>
    <div class="content-box">
        <div class="container">
            <div class="content_title">
                <p>登录日志</p>
                <div class="content_search">
                    <el-form :inline="true" size="mini" :model="formInline" ref="formInline" class="demo-form-inline row">
                        <div class="qZ_query_title">
                            <div class="qZ_query_title_left">
                                <el-form-item label="登录用户：" prop="username">
                                    <el-input v-model="searchDataName" placeholder="单行输入"></el-input>
                                </el-form-item>
                                <el-form-item label="开始时间" prop="start_time">
                                    <el-date-picker
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        v-model="searchDatastart_time"
                                        type="date"
                                        placeholder="选择日期"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="结束时间" prop="end_time">
                                    <el-date-picker
                                        format="yyyy-MM-dd HH:mm"
                                        value-format="yyyy-MM-dd HH:mm"
                                        v-model="searchDataend_time"
                                        type="date"
                                        placeholder="选择日期"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="qZ_query_title_right">
                                <el-button type="primary" @click="Search()">查询</el-button>
                                <el-button label="left" @click="Reset()">重置</el-button>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="content_table">
                <el-table :data="tableData" border max-height="600">
                    <el-table-column prop="" align="center" width="50" label="序号">
                        <template slot-scope="scope">
                            {{ scope.$index + 1 }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="userLog.timeCreated" align="center" label="登录时间"> </el-table-column>
                    <el-table-column prop="userLog.username" align="center" label="登录用户"> </el-table-column>
                    <!-- <el-table-column prop="userLog.ip" align="center" label="登录IP"> </el-table-column> -->
                    <el-table-column prop="userLog.action" align="center" label="登录状态"> </el-table-column>
                </el-table>
            </div>
            <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>
        </div>
    </div>
</template>
 
<script>
import { userIPlist } from '@/api/file';
export default {
    data() {
        return {
            pageSize: 10,
            currentPage: 1,
            total: 0,
            num: 0,
            formInline: {},
            nowformInline: {},
            value: '',
            tableData: [],
            name: null,
            start_time: null,
            end_time: null,
            searchDataName: null,
            searchDatastart_time: null,
            searchDataend_time: null
        };
    },
    created() {},

    mounted() {
        this.getJournalList();
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getJournalList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        //    获取日志表

        async getJournalList() {
            let limit = this.pageSize;
            let page = this.currentPage;

            let res = await userIPlist({
                limit,
                page,
                name: this.name,
                end_time: this.end_time,
                start_time: this.searchDatastart_time
            });
            if (res.code == 200) {
                console.log(res.data);
                this.tableData = res.data;
                this.total = res.total;
                this.num = parseInt(this.total / limit + 1);
            }
        },

        GMTToStr(time) {
            // 将 GMT格式的时间字符串 格式化为正常时间字符串
            let date = new Date(time);
            let Str =
                date.getFullYear() +
                '-' +
                this.handleTimeStr(date.getMonth() + 1) +
                '-' +
                this.handleTimeStr(date.getDate()) +
                ' ' +
                this.handleTimeStr(date.getHours()) +
                ':' +
                this.handleTimeStr(date.getMinutes()) +
                ':' +
                this.handleTimeStr(date.getSeconds());
            return Str;
        },
        handleTimeStr(tStr) {
            // 解决 10:2:2 出现各位数字格式化为 10:02:02
            if (tStr < 10) {
                return '0' + tStr;
            } else {
                return tStr;
            }
        },
        //   查询功能

        async Search(formInline) {
            (this.start_time = this.searchDatastart_time), (this.end_time = this.searchDataend_time), (this.name = this.searchDataName);
            this.getJournalList();
        },

        Reset(formInline) {
            (this.name = null),
                (this.start_time = null),
                (this.end_time = null),
                (this.searchDataName = null),
                (this.searchDatastart_time = null),
                (this.searchDataend_time = null);
            this.getJournalList();
        }
    }
};
</script>

<style lang="scss" scoped>
.conent {
    widows: 1600px;
    height: 982px;
    margin: 0 auto;
}
.content_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    p {
        font-size: 20px;
        font-family: 'Franklin Gothic Medium';
    }
}

.content_search {
    margin-top: 30px;
    width: 99%;
    height: 80px;
}
.qZ_query_title {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.content_table {
    margin-top: 30px;
}
</style>